var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"mb-3\">\n  <label for=\"form-file\" class=\"form-label\">\n    "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"csv_import",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Invite multiple users by uploading a CSV file."},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":113}}}))
    + "\n  </label>\n  <input accept=\".csv\" class=\"form-control\" type=\"file\" id=\"form-file\">\n  <a class=\"d-block mt-2\" href=\"/invitations_example.csv\">\n    "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"download_csv_example",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Download Example"},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":93}}}))
    + "\n  </a>\n</div>\n";
},"useData":true});