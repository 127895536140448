import { app } from '@/application'
import { render, renderInvitationForm } from '@/components'
import template from './InvitationFormList.hbs'
import I18n from '@/shared/components/i18n'

const i18nInvitationNotice = count => I18n.t('create', {
  count,
  scope: 'js.invitations.notice',
  defaultValue: 'User successfully invited. An Email has been sent to the user.'
})

const onRender = (_container, invitationRepo) => {
  const container = document.getElementById('invitation-forms')

  const addButton = document.getElementById('add-user-button')
  addButton.addEventListener('click', onAddClick(container, invitationRepo))

  const submitButton = document.getElementById('submit-button')
  submitButton.addEventListener(
    'click',
    onSubmitClick(container, invitationRepo)
  )

  invitationRepo.subscribe('data:add', onAdd(container, invitationRepo))
  invitationRepo.subscribe('data:change', onChange(container, invitationRepo))
  invitationRepo.subscribe('data:remove', onRemove(invitationRepo))
  invitationRepo.subscribe('error', onError(container))
}

const checkValidity = container => {
  const items = container.querySelectorAll('.form-floating input, select')
  const invalidItem = Array.from(items).find(item => !item.checkValidity())

  if (invalidItem) {
    const button = invalidItem
      .closest('.accordion-item').querySelector('.accordion-button')

    if (button.classList.contains('collapsed')) button.click()

    invalidItem.reportValidity()
  }

  return !invalidItem
}

const onAdd = (container, invitationRepo) => (_event, invitation) => {
  // Close any currently open accordion-item
  container.querySelectorAll('.accordion-item').forEach(item => {
    item.querySelector('.accordion-button').classList.add('collapsed')
    item.querySelector('.accordion-collapse').classList.remove('show')
  })

  renderInvitationForm(container, { invitation, invitationRepo })

  document.getElementById('submit-button').disabled = false
}

const onAddClick = (container, invitationRepo) => () => {
  if (checkValidity(container)) invitationRepo.add({})
}

const onChange = (container, invitationRepo) => (_event, invitations) => {
  container.innerHTML = ''
  invitations.forEach(invitation => {
    renderInvitationForm(container, {
      invitation: { collapsed: true, ...invitation },
      invitationRepo
    })
  })

  document.getElementById('submit-button').disabled = false
}

const onError = container => (_event, errors) => {
  const pointerRegex = /data\/(\d+)\/attributes\/(\w+)/
  const invitationElements = container.getElementsByClassName('accordion-item')

  Object.values(errors).forEach(({ detail, title, source }) => {
    const attribute = ''

    if (source) {
      const [, position, attribute] = source.pointer.match(pointerRegex)
      invitationElements[position]
        .querySelector(`[data-name=${attribute}]`)
        .setCustomValidity(detail)
    }

    app.notifyAlert(`${title}: ${attribute} ${detail}`)
  })

  checkValidity(container)
}

const onRemove = invitationRepo => (_event, invitation) => {
  document.getElementById(`new-user-${invitation.id}`).remove()

  if (invitationRepo.isEmpty())
    document.getElementById('submit-button').disabled = true
}

const onSubmitClick = (container, invitationRepo) => ({ target }) => {
  if (!checkValidity(container)) return

  target.disabled = true

  const spinner = target.getElementsByClassName('spinner-border')[0]
  spinner.classList.toggle('d-none', false)

  invitationRepo.createAll().then(() => {
    document.location.assign('/users')
  }).catch(() => {
    target.disabled = false
    spinner.classList.toggle('d-none', true)
  })
}

export default (container, invitationRepo) => {
  render(container, template, invitationRepo, onRender)
}
