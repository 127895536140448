import { app } from '@/application'
import { bodyHasClasses } from '@/helpers/bodyHasClasses'
import I18n from '@/shared/components/i18n'
import {
  renderBatchButton,
  renderPaginator,
  renderSearchFilter,
  renderUserCancelModal,
  renderUserList
} from '@/components'
import { UserRepository } from '@/repositories'

const i18nInviteNotice = I18n.t('invitation', {
  count: 1,
  scope: 'js.users.notice',
  defaultValue:
    'Invitation successfully reissued. An email has been sent to the user.'
})

const i18nSearchPlaceholder = I18n.t('placeholder', {
  scope: 'js.users.index',
  defaultValue: 'Search for name, email, status or tags'
})

const userRepo = new UserRepository()

const _initIndex = () => {
  renderBatchButton(document.getElementById('batch-button'), userRepo)
  renderUserCancelModal(document.getElementById('user-cancel-modal'), userRepo)

  const usersContainer = document.getElementById('users-container')

  renderSearchFilter(usersContainer, {
    placeholder: i18nSearchPlaceholder,
    selector: '.user-table-row',
    list: usersContainer
  })

  renderUserList(usersContainer, userRepo)

  renderPaginator(document.getElementById('users-paginator'), {
    list: usersContainer, selector: '.user-table-row.valid'
  })
}

const _initEdit = () => {
  if (bodyHasClasses(['registrations'])) return

  const container = document.body

  const user = JSON.parse(container.querySelector('form').dataset.user)
  const buttons = container.getElementsByClassName('action-btn')

  userRepo.add(user)

  for (const button of buttons) {
    button.addEventListener('click', event => {
      userRepo.action(event.target.dataset.action, user.id)
        .then(() => app.notifySuccess(i18nInviteNotice))
    })
  }
}

export default () => {
  if (bodyHasClasses(['users', 'index'])) _initIndex()
  if (bodyHasClasses(['users', 'edit'])) _initEdit()
}
