import { app } from '@/application'
import { render } from '@/components'
import template from './UserCancelModal.hbs'
import I18n from '@/shared/components/i18n'

const i18nCancelAccountNotice = I18n.t('cancel_account', {
  scope: 'js.users.notice',
  defaultValue: 'Account successfully cancelled.'
})

const onRender = (container, usersRepo) => {
  initConfirmation(usersRepo)
  initShow(container)
}

const initConfirmation = usersRepo => {
  const confirmationBtn = document.getElementById('confirmation-btn')

  confirmationBtn.addEventListener('click', event => {
    const { userId } = event.target.dataset
    usersRepo.delete(userId).then(() => {
      app.notifySuccess(i18nCancelAccountNotice)
    })
  })
}

const initShow = container => {
  container.addEventListener('show.bs.modal', ({ relatedTarget }) => {
    const userId = relatedTarget.getAttribute('data-bs-user-id')

    const confirmationBtn = document.getElementById('confirmation-btn')
    confirmationBtn.dataset.userId = userId
  })
}

export default (container, usersRepo) => {
  render(
    container,
    template,
    usersRepo,
    onRender
  )
}
