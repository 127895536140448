import { notifyChange, render, renderUserListItem } from '@/components'
import template from './UserList.hbs'

const onRender = (container, usersRepo) => {
  const table = document.getElementById('users-table')

  usersRepo.subscribe(
    'data:change',
    onChange(container, table, usersRepo)
  )
  usersRepo.subscribe('data:remove', onRemove(table))
  usersRepo.subscribe('data:deselect', onDeselect(table))

  usersRepo.getAll()
}

const onChange = (container, table, usersRepo) => (_event, users) => {
  table.innerHTML = ''
  users.forEach(user => { renderUserListItem(table, { user, usersRepo }) })

  notifyChange(container.parentElement)
}

const onDeselect = table => (_event, object) => {
  if (object !== null) return

  table.querySelectorAll('input[type="checkbox"]').forEach(row => {
    row.checked = false
  })
}

const onRemove = container => (_event, user) => {
  document.getElementById(`user_${user.id}`).remove()
  notifyChange(container)
}

export default (container, usersRepo) => {
  render(
    container,
    template,
    usersRepo,
    onRender
  )
}
