import BaseRepository from './Base'
import decorateInvitation from '@/decorators/decorateInvitation'
import _partition from 'lodash/partition'
import apiClient from '@/apiClient'

export default class InvitationRepository extends BaseRepository {
  constructor() {
    super()
    this._counter = 0
    this._invitations = []
  }

  add(invitation) {
    this._invitations.push(decorateInvitation(invitation))
    this.notify('data:add', invitation)
  }

  createAll() {
    return apiClient.create(
      'user',
      this._invitations,
      { path: 'invitations/bulk' }
    ).catch(error => {
      this._error(error)
      throw error
    })
  }

  change(invitations) {
    this._invitations =
      invitations.map(invitation => decorateInvitation(invitation))

    this.notify('data:change', this._invitations)
  }

  getAll() {
    return this._invitations
  }

  isEmpty() {
    return this._invitations.length === 0
  }

  remove(id) {
    const [removed, remainder] =
      _partition(this._invitations, invitation => invitation.id === id)

    this._invitations = remainder
    this.notify('data:remove', removed[0])
  }

  update({ id, ...attributes }) {
    const index =
      this._invitations.findIndex(invitation => invitation.id === id)

    const object = decorateInvitation({
      ...this._invitations[index],
      ...attributes
    })

    this._invitations[index] = object
    this.notify('data:update', object)
  }
}
