import renderBatchButton from './BatchButton/BatchButton.js'
import renderInvitationForm from './InvitationForm/InvitationForm.js'
import renderInvitationFormList
  from './InvitationFormList/InvitationFormList.js'
import renderInvitationCSVImport
  from './InvitationCSVImport/InvitationCSVImport.js'
import renderPaginator from './Paginator/Paginator.js'
import renderSearchFilter from './SearchFilter/SearchFilter.js'
import renderUserCancelModal from './UserCancelModal/UserCancelModal.js'
import renderUserList from './UserList/UserList.js'
import renderUserListItem from './UserListItem/UserListItem.js'

export {
  renderBatchButton,
  renderInvitationForm,
  renderInvitationFormList,
  renderInvitationCSVImport,
  renderPaginator,
  renderSearchFilter,
  renderUserCancelModal,
  renderUserList,
  renderUserListItem
}

export const notifyChange = container => {
  container.dispatchEvent(new Event('change'))
}

export const render = (container, template, obj = {}, onRender = () => {}) => {
  container.insertAdjacentHTML('beforeend', template(obj))

  onRender(container.lastElementChild, obj)
}
