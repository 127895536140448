const badgeColor = user => {
  const badgeColors = {
    activated: 'bg-success',
    clickwrap_pending: 'bg-warning',
    deleted: 'bg-light text-dark',
    expired: 'bg-light text-dark',
    invited: 'bg-light text-dark',
    locked: 'bg-danger',
    unconfirmed: 'bg-warning'
  }
  return badgeColors[user.state]
}

export default user => ({
  ...user,
  badgeColor: badgeColor(user),
  selfLink: `${window.origin}/users/${user.id}`,
  stateName: user.state === 'activated' ? 'active' : user.state,
  isAdmin: user.role === 'admin',
  isLocked: user.state === 'locked',
  isReinviteable: ['expired', 'invited'].includes(user.state)
})
