import { render } from '@/components'
import Kaminari from '@/app/components/search/adapters/kaminari'

const onRender = (container, object) => {
  displayPage(object)
  initOnClick(container, object)

  object.list.addEventListener('change', () => {
    refresh(container, { ...object, page: 1 })
  })
}

const decorate = ({ page = 1, pageSize = 10, list, selector }) => {
  const items = list.querySelectorAll(selector)
  const pages = items ? Math.ceil(items.length / pageSize) : 1

  return {
    page,
    pageSize,
    list,
    selector,
    pager: Kaminari.decorate({ page, pages }, pageBuilder(page)).pager
  }
}

const displayPage = ({ page, pageSize, list, selector }) => {
  const items = Array.from(list.querySelectorAll(selector))

  if (items.length === 0) return

  const pageStart = (page - 1) * pageSize
  items.forEach(item => { item.classList.add('d-none') })
  items.slice(pageStart, pageStart + pageSize).forEach(item => {
    item.classList.remove('d-none')
  })
}

const initOnClick = (container, object) => {
  container.querySelectorAll('a.page-link').forEach(button => {
    button.addEventListener('click', event => {
      event.preventDefault()
      const page = Number(event.target.dataset.page)
      refresh(container, { ...object, page })
    })
  })
}

const pageBuilder = currentPage => (page, type = 'page') => {
  let rel
  if (page === currentPage - 1) rel = 'prev'
  else if (page === currentPage + 1) rel = 'next'

  const isCurrentPage = page === currentPage

  return { page, type, rel, isCurrentPage }
}

const refresh = (container, object) => {
  container.innerHTML = Kaminari.templates.pagination(decorate(object))
  displayPage(object)
  initOnClick(container, object)
}

export default (container, object) => {
  render(
    container,
    Kaminari.templates.pagination,
    decorate(object),
    onRender
  )
}
