import { app } from '@/application'
import { render } from '@/components'
import template from './UserListItem.hbs'
import decorateUser from '@/decorators/decorateUser'
import I18n from '@/shared/components/i18n'

const onRender = (element, { usersRepo, ...user }) => {
  initActionButtons(element, usersRepo)
  initCheckbox(element, user, usersRepo)
}

const i18nNotices = {
  invitation: I18n.t('invitation', {
    count: 1,
    scope: 'js.users.notice',
    defaultValue:
      'Invitation successfully reissued. An email has been sent to the user.'
  }),
  password: I18n.t('password_reset', {
    count: 1,
    scope: 'js.users.notice',
    defaultValue:
      'Password reset process initiated. An email has been sent to the user.'
  }),
  unlock: I18n.t('unlock', {
    count: 1,
    scope: 'js.users.notice',
    defaultValue:
      'Account unlocking process initiated. An email has been sent to the user.'
  })
}

const initCheckbox = (element, user, usersRepo) => {
  const checkbox = element.querySelector('input[type=checkbox]')
  checkbox.addEventListener('change', event => {
    const { target } = event

    if (target.checked)
      usersRepo.select(user)
    else
      usersRepo.deselect(user.id)
  })
}

const initActionButtons = (element, usersRepo) => {
  const buttons = element.getElementsByClassName('action-btn')
  for (const button of buttons) {
    button.addEventListener('click', event => {
      const { action } = event.target.dataset
      usersRepo.action(action, element.dataset.id)
        .then(() => app.notifySuccess(i18nNotices[action]))
    })
  }
}

export default (container, { user, usersRepo }) => {
  render(
    container,
    template,
    decorateUser({ ...user, usersRepo }),
    onRender
  )
}
