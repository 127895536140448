import { render } from '@/components'
import template from './InvitationCSVImport.hbs'
import _zipObject from 'lodash/zipObject'
import { camelCase } from 'lodash'

const reader = new FileReader()

const onRender = (_container, invitationRepo) => {
  const formFile = document.getElementById('form-file')
  formFile.addEventListener('change', onChange)

  reader.addEventListener('load', onLoad(invitationRepo))
}

const onChange = event => {
  const file = event.target.files[0]

  if (file) reader.readAsText(file)
}

const onLoad = invitationRepo => () => {
  let [headers, ...rows] = parseCSV(reader.result)
  headers = headers.map(header => camelCase(header))
  headers[headers.indexOf('role')] = 'productUserGroup'

  invitationRepo.change(rows.map(row => {
    const { tags, ...attributes } = _zipObject(headers, row)
    return {
      ...attributes,
      tags: tags.split('#').splice(1)
    }
  }))
}

const parseCSV = text => text
  .split(/\n+/)
  .filter(line => line !== '')
  .map(line => line.replace(/\s/g, '').split(','))

export default (container, invitationRepo) => {
  render(container, template, invitationRepo, onRender)
}
