var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"badge rounded-pill bg-danger\">\n              "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"role") : depth0), depth0))
    + "\n            </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <span class='badge bg-secondary'>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <li>\n              <button\n                class=\"action-btn dropdown-item text-primary\"\n                data-action=\"unlock\"\n              >\n                "
    + container.escapeExpression(__default(require("../../shared/handlebars/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"unlock",{"name":"t","hash":{"scope":"js.users.index","defaultValue":"Unlock"},"data":data,"loc":{"start":{"line":56,"column":16},"end":{"line":56,"column":75}}}))
    + "\n              </button>\n            </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            <li>\n              <button\n                class=\"action-btn dropdown-item text-primary\"\n                data-action=\"invitation\"\n              >\n                "
    + container.escapeExpression(__default(require("../../shared/handlebars/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"reinvite",{"name":"t","hash":{"scope":"js.users.index","defaultValue":"Reissue invite"},"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":66,"column":85}}}))
    + "\n              </button>\n            </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "            <li>\n              <button\n                class=\"action-btn dropdown-item text-primary\"\n                data-action=\"password\"\n              >\n                "
    + container.escapeExpression(__default(require("../../shared/handlebars/t.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"reset_password",{"name":"t","hash":{"scope":"js.users.index","defaultValue":"Reset Password"},"data":data,"loc":{"start":{"line":76,"column":16},"end":{"line":76,"column":91}}}))
    + "\n              </button>\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"user-table-row valid\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" id=\"user_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n  <td style=\"width: 5%;\">\n    <input class=\"form-check-input\" type=\"checkbox\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n  </td>\n  <td>\n    <table>\n      <tr>\n        <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"selfLink") : depth0), depth0))
    + "/edit\">\n          "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"firstName") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lastName") : depth0), depth0))
    + " ("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + ")\n        </a>\n      </tr>\n      <tr>\n        <td>\n          <span class=\"badge rounded-pill "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"badgeColor") : depth0), depth0))
    + "\">\n            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"stateName") : depth0), depth0))
    + "\n          </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":10},"end":{"line":21,"column":17}}})) != null ? stack1 : "")
    + "        </td>\n        <td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":10},"end":{"line":26,"column":19}}})) != null ? stack1 : "")
    + "        </td>\n      </tr>\n    </table>\n  </td>\n  <td class=\"text-end\">\n      <div class=\"dropdown\">\n        <a\n          data-bs-toggle=\"dropdown\"\n          id=\"dropdownMenu2\"\n          type=\"button\"\n        >\n          <i class=\"fa-ellipsis-h fa-fw far\"></i>\n        </a>\n\n        <ul aria-labelledby=\"dropdownMenu2\" class=\"dropdown-menu\">\n          <li>\n            <a\n              class=\"dropdown-item text-primary\"\n              href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"selfLink") : depth0), depth0))
    + "/edit\"\n            >\n              "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"edit",{"name":"t","hash":{"scope":"js.users.index","defaultValue":"Edit"},"data":data,"loc":{"start":{"line":47,"column":14},"end":{"line":47,"column":69}}}))
    + "\n            </a>\n          </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isLocked") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":10},"end":{"line":59,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isReinviteable") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":10},"end":{"line":69,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"isReinviteable") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":10},"end":{"line":79,"column":21}}})) != null ? stack1 : "")
    + "          <li>\n            <button\n              class=\"dropdown-item text-danger\"\n              data-bs-target=\"#delete-confirmation\"\n              data-bs-toggle=\"modal\"\n              data-bs-user-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n            >\n              "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"cancel_account",{"name":"t","hash":{"scope":"js.users.index","defaultValue":"Cancel Account"},"data":data,"loc":{"start":{"line":87,"column":14},"end":{"line":87,"column":89}}}))
    + "\n            </button>\n          </li>\n        </ul>\n      </div>\n  </td>\n</tr>\n";
},"useData":true});