import BaseRepository from './Base'
import _partition from 'lodash/partition.js'
import apiClient from '@/apiClient'

export default class UserRepository extends BaseRepository {
  constructor() {
    super()
    this._users = []
    this._selected = []
  }

  action(actionName, id) {
    const user = this._users.find(user => user.id === id)
    return apiClient.create('user', user, { path: `${actionName}s` })
      .catch(this._error.bind(this))
  }

  add(user) {
    this._users.push(user)
    this.notify('data:add', user)
  }

  bulkAction(actionName) {
    return apiClient
      .create('user', this._selected, { path: `${actionName}s/bulk` })
      .then(() => { this.deselectAll() })
      .catch(this._error.bind(this))
  }

  deselect(id) {
    const [removed, remainder] = _partition(
      this._selected,
      user => user.id === id
    )

    this._selected = remainder
    this.notify('data:deselect', removed)
  }

  deselectAll() {
    this._selected = []
    this.notify('data:deselect', null)
  }

  delete(id) {
    return apiClient.destroy('user', id)
      .then(() => {
        const [user, remainder] =
          _partition(this._users, user => user.id === id)

        this._users = remainder
        this.notify('data:remove', user[0])
      })
      .catch(this._error.bind(this))
  }

  getAll() {
    return apiClient.get('users', null, {
      fields: {
        user: 'email,firstName,lastName,role,state,tags'
      }
    }).then(response => {
      this._users = response.data
      this.notify('data:change', this._users)
    })
      .catch(this._error.bind(this))
  }

  getAllSelected() {
    return this._selected
  }

  select(user) {
    this._selected.push(user)
    this.notify('data:select', user)
  }
}
