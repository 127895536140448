let counter = 0

export default invitation => {
  invitation.id ??= String(counter++)

  if (invitation.tags && typeof invitation.tags === 'string')
    invitation.tags = invitation.tags.split(/\s*,\s*/)

  const isAdmin = () => invitation.productUserGroup === 'admin'

  if (!isAdmin()) invitation.productUserGroup = 'default'

  return {
    ...invitation,
    isAdmin
  }
}
