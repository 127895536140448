var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div\n  aria-labelledby=\"delete-confirmation-label\"\n  aria-hidden=\"true\"\n  class=\"modal\"\n  id=\"delete-confirmation\"\n  role=\"dialog\"\n  tabindex=\"-1\"\n>\n  <div class=\"modal-dialog\" role=\"document\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <h5 class=\"modal-title\" id=\"delete-confirmation-label\">\n          "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"delete_account_confirmation_header",{"name":"t","hash":{"scope":"js.users.edit","defaultValue":"Confirm account deletionddd"},"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":13,"column":117}}}))
    + "\n        </h5>\n        <button\n          aria-label=\"Close\"\n          class=\"btn-close\"\n          data-bs-dismiss=\"modal\"\n          type=\"button\"\n        >\n        </button>\n      </div>\n      <div class=\"modal-body\">\n        <p>\n          "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"delete_account_confirmation_body",{"name":"t","hash":{"scope":"js.users.edit","defaultValue":""},"data":data,"loc":{"start":{"line":25,"column":10},"end":{"line":25,"column":88}}}))
    + "\n        </p>\n        <p>\n          "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"delete_account_confirmation_body_proceed",{"name":"t","hash":{"scope":"js.users.edit","defaultValue":""},"data":data,"loc":{"start":{"line":28,"column":10},"end":{"line":28,"column":96}}}))
    + "\n        </p>\n      </div>\n      <div class=\"modal-footer\">\n        <button class=\"btn btn-link\" data-bs-dismiss=\"modal\" type=\"button\">\n          "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"delete_account_confirmation_no",{"name":"t","hash":{"scope":"js.users.edit","defaultValue":"No, not right now"},"data":data,"loc":{"start":{"line":33,"column":10},"end":{"line":33,"column":103}}}))
    + "\n\n        </button>\n        <a class=\"btn btn-danger\" data-bs-dismiss=\"modal\" id=\"confirmation-btn\">\n          "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"delete_account_confirmation_yes",{"name":"t","hash":{"scope":"js.users.edit","defaultValue":"Yes, cancel this account"},"data":data,"loc":{"start":{"line":37,"column":10},"end":{"line":37,"column":111}}}))
    + "\n        </a>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});