import { render } from '@/components'
import template from './InvitationForm.hbs'

const onRender = (element, invitation) => {
  initEventListeners(element, invitation, 'input')
  initEventListeners(element, invitation, 'change')
  initRemoveButton(element, invitation)

  invitation.invitationRepo.subscribe('data:update', onUpdate(invitation.id))

  element.querySelector('input[data-name=firstName]').focus()
}

const initEventListeners = (container, invitation, eventName) => {
  $(container).on(eventName, ({ target }) => {
    // Remove custom validity error on change/input.
    target.setCustomValidity('')

    const { id } = container.dataset
    const { name } = target.dataset

    invitation.invitationRepo.update({ id, [name]: target.value })
  })
}

const onUpdate = id => (_event, invitation) => {
  if (id !== invitation.id) return

  const container = document.getElementById(`new-user-${invitation.id}`)

  for (const attr in invitation) {
    container.querySelectorAll(`[data-value=${attr}]`).forEach(element => {
      element.innerHTML = invitation[attr]
    })
  }
}

const initRemoveButton = (container, invitation) => {
  const removeButton = container.querySelector('[data-name=remove-button]')

  removeButton.addEventListener('click', () => {
    invitation.invitationRepo.remove(invitation.id)
  })
}

export default (container, { invitation, invitationRepo }) => {
  render(
    container,
    template,
    { ...invitation, invitationRepo },
    onRender
  )
}
