import { bodyHasClasses } from '@/helpers/bodyHasClasses'
import { renderInvitationFormList, renderInvitationCSVImport }
  from '@/components'
import { InvitationRepository } from '@/repositories'

export default () => {
  if (!bodyHasClasses(['users', 'invitations', 'new'])) return

  const invitationRepo = new InvitationRepository()

  renderInvitationCSVImport(
    document.getElementById('invitations-form-file'),
    invitationRepo
  )

  renderInvitationFormList(
    document.getElementById('invitations-form-container'),
    invitationRepo
  )
}
