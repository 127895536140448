var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"btn-group p-0 m-0\" id=\"bulk-actions-btn\">\n  <button\n    class=\"btn btn-outline-secondary dropdown-toggle\"\n    data-bs-toggle=\"dropdown\"\n    disabled\n  >\n    "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"batch",{"name":"t","hash":{"scope":"js.users.index","defaultValue":"Batch"},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":62}}}))
    + "\n    <span class=\"badge rounded-pill bg-primary select-counter\"></span>\n  </button>\n  <ul class=\"dropdown-menu\">\n    <li>\n      <a class=\"dropdown-item disabled\" data-action=\"unlock\" href=\"#\">\n        "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"unlock",{"name":"t","hash":{"scope":"js.users.index","defaultValue":"Unlock"},"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":67}}}))
    + "\n      </a>\n    </li>\n    <li>\n      <a class=\"dropdown-item disabled\" data-action=\"invitation\" href=\"#\">\n        "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"reinvite",{"name":"t","hash":{"scope":"js.users.index","defaultValue":"Reissue invite"},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":77}}}))
    + "\n      </a>\n    </li>\n    <li>\n      <a class=\"dropdown-item\" data-action=\"password\" href=\"#\">\n        "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"reset_password",{"name":"t","hash":{"scope":"js.users.index","defaultValue":"Reset password"},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":83}}}))
    + "\n      </a>\n    </li>\n  </ul>\n</div>\n";
},"useData":true});