import { app } from '@/application'
import { DateTimePicker } from '@/shared/components/observers/date_time_picker'
import { Select2 } from '@/shared/components/observers/select2'

import '@/shared/components/bootstrap'
import '@/shared/components/browser_update'
import '@/accounts/stylesheets/accounts'

import initializers from '@/accounts/initializers'
import { authorize } from '@/apiClient/oauth'

$(() => {
  new DateTimePicker().start('body')
  new Select2().start('body')
})

if (window.USER.id) {
  authorize().then(isAuthorized => {
    if (isAuthorized) {
      app.fetchCurrentUser()
        .then(() => {
          initializers.forEach(initializer => initializer())
        })
    }
  })
}

$(() => {
  // Dynamic confirmation modals upon OAuth application authorisation
  // revocation.
  //
  $('body.authorized-applications.index').on(
    'show.bs.modal', '#revoke-confirmation', event => {
      const $link = $(event.relatedTarget)
      $('.modal-title', event.currentTarget).text($link.data('applicationName'))
      $('.btn.yes', event.currentTarget).prop(
        'href',
        $link.data('applicationUrl')
      )
    }
  )
})
