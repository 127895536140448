import { notifyChange, render } from '@/components'
import template from './SearchFilter.hbs'

const onRender = (_container, { list, selector }) => {
  const searchInput = document.getElementById('search-input')
  searchInput.addEventListener('input', onInput(list, selector))
}

const onInput = (container, selector) => event => {
  const inputValue = event.target.value.toLowerCase()
  const items = container.querySelectorAll(selector)

  if (inputValue === '') {
    items.forEach(item => toggle(item, true))
    notifyChange(container)
    return
  }

  const terms = inputValue.split(/\s+/)
  items.forEach(item => toggle(item, match(terms, item)))
  notifyChange(container)
}

const match = (terms, item) => {
  const userText = item.innerText.toLowerCase()
  return terms.every(term => userText.includes(term))
}

const toggle = (item, valid) => {
  item.classList.toggle('d-none', !valid)
  item.classList.toggle('valid', valid)
}

export default (container, object) => {
  render(
    container,
    template,
    object,
    onRender
  )
}
