import _defer from 'lodash/defer.js'

export default class Base {
  constructor() {
    this._observers = {}
  }

  decorate(object) {
    return object
  }

  notify(eventType, ...args) {
    const event = { target: this, type: eventType }
    $.map(this._observers[eventType], fn => _defer(fn, event, ...args))
  }

  subscribe(event, fn) {
    (this._observers[event] ??= []).push(fn)
  }

  _error(e) {
    this.notify('error', e)
    return e
  }
}
