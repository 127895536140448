import { app } from '@/application'
import { render } from '@/components'
import template from './BatchButton'
import I18n from '@/shared/components/i18n'

const scope = 'js.users.notice'

const i18nInviteNoticedDefault =
  'Invitation successfully reissued. An email has been sent to the user.'

const i18nPasswordResetDefault =
  'Password reset process initiated. An email has been sent to the user.'

const i18nUnlockDefault =
  'Account unlocking process initiated. An email has been sent to the user.'

const i18nNotices = count => ({
  invitation: I18n.t('invitation', {
    count, scope, defaultValue: i18nInviteNoticedDefault
  }),
  password: I18n.t('password_reset', {
    count, scope, defaultValue: i18nPasswordResetDefault
  }),
  unlock: I18n.t('unlock', {
    count, scope, defaultValue: i18nUnlockDefault
  })
})

const onRender = (container, usersRepo) => {
  for (const item of container.getElementsByClassName('dropdown-item'))
    item.addEventListener('click', onClick(usersRepo))

  usersRepo.subscribe('data:select', onChange(container, usersRepo))
  usersRepo.subscribe('data:deselect', onChange(container, usersRepo))
}

const onChange = (container, usersRepo) => (_event, _object) => {
  const users = usersRepo.getAllSelected()

  container.querySelector('.dropdown-toggle').disabled = users.length === 0
  container.getElementsByClassName('select-counter')[0].innerHTML =
    users.length === 0 ? '' : users.length

  container.querySelector('[data-action=invitation]').classList.toggle(
    'disabled', users.some(user => !user.isReinviteable)
  )

  container.querySelector('[data-action=password]').classList.toggle(
    'disabled', users.some(user => user.isReinviteable)
  )

  container.querySelector('[data-action=unlock]').classList.toggle(
    'disabled', users.some(user => !user.isLocked)
  )
}

const onClick = usersSelectRepo => event => {
  const { action } = event.target.dataset
  const count = usersSelectRepo.getAllSelected().length

  usersSelectRepo.bulkAction(action)
    .then(() => app.notifySuccess(i18nNotices(count)[action]))
}

export default (container, usersRepo) => {
  render(
    container,
    template,
    usersRepo,
    onRender
  )
}
