var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "collapsed";
},"3":function(container,depth0,helpers,partials,data) {
    return "show";
},"5":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"accordion-item\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" id=\"new-user-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n  <h2 class=\"accordion-header\">\n    <button\n      class=\"accordion-button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"collapsed") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":30},"end":{"line":4,"column":63}}})) != null ? stack1 : "")
    + "\"\n      data-bs-target=\"#new-user-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "-content\"\n      data-bs-toggle=\"collapse\"\n      type=\"button\"\n    >\n      <span data-value=\"firstName\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"firstName") : depth0), depth0))
    + "</span>&nbsp;\n      <span data-value=\"lastName\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lastName") : depth0), depth0))
    + "</span>\n    </button>\n  </h2>\n\n  <div\n    class=\"accordion-collapse collapse "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"collapsed") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":39},"end":{"line":15,"column":75}}})) != null ? stack1 : "")
    + "\"\n    data-bs-parent=\"#new-users-container\"\n    id=\"new-user-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "-content\"\n  >\n    <div class=\"accordion-body p-0 pt-3\">\n      <div class=\"form-floating mb-3\">\n        <input\n          id=\"user-first-name\"\n          class=\"form-control\"\n          data-name=\"firstName\"\n          placeholder=\"First name\"\n          required=\"required\"\n          type=\"text\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"firstName") : depth0), depth0))
    + "\"\n        />\n        <label class=\"form-label\" for=\"user-first-name\">\n          "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"first_name",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"First name"},"data":data,"loc":{"start":{"line":31,"column":10},"end":{"line":31,"column":83}}}))
    + "\n        </label>\n      </div>\n\n      <div class=\"form-floating mb-3\">\n        <input\n          id=\"user-last-name\"\n          class=\"form-control\"\n          data-name=\"lastName\"\n          placeholder=\"Last name\"\n          required=\"required\"\n          type=\"text\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lastName") : depth0), depth0))
    + "\"\n        />\n        <label class=\"form-label\" for=\"user-last-name\">\n          "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"last_name",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Last name"},"data":data,"loc":{"start":{"line":46,"column":10},"end":{"line":46,"column":81}}}))
    + "\n        </label>\n      </div>\n\n      <div class=\"form-floating mb-3\">\n        <input\n          autocomplete=\"email\"\n          class=\"form-control\"\n          data-name=\"email\"\n          placeholder=\"Email\"\n          required=\"required\"\n          type=\"email\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\"\n        />\n        <label class=\"form-label\" for=\"user_email\">\n          "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"emails",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Emails"},"data":data,"loc":{"start":{"line":61,"column":10},"end":{"line":61,"column":75}}}))
    + "\n        </label>\n      </div>\n\n      <div class=\"form-floating mb-3\">\n        <input\n          autocomplete=\"user_tags\"\n          class=\"form-control\"\n          data-name=\"tags\"\n          placeholder=\"Tags\"\n          type=\"text\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tags") : depth0), depth0))
    + "\"\n        />\n        <label class=\"form-label\" for=\"user_tags\">\n          "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"tags",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Tags (comma separated)"},"data":data,"loc":{"start":{"line":75,"column":10},"end":{"line":75,"column":89}}}))
    + "\n        </label>\n      </div>\n\n      <div class=\"form-floating\">\n        <select\n          class=\"form-select\"\n          data-allow-clear=\"false\"\n          data-minimum-results-for-search=\"Infinity\"\n          data-name=\"product-user-group\"\n          data-width=\"100%\"\n          placeholder=\""
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"role",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Role"},"data":data,"loc":{"start":{"line":86,"column":23},"end":{"line":86,"column":84}}}))
    + "\"\n          required=\"required\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"role") : depth0), depth0))
    + "\"\n        >\n          <option\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../../shared/handlebars/eq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"productUserGroup") : depth0),"admin",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":91,"column":18},"end":{"line":91,"column":47}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":12},"end":{"line":91,"column":64}}})) != null ? stack1 : "")
    + "\n            value=\"admin\"\n          >\n            "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"admin",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Admin"},"data":data,"loc":{"start":{"line":94,"column":12},"end":{"line":94,"column":75}}}))
    + "\n          </option>\n          <option\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../../shared/handlebars/eq.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"productUserGroup") : depth0),"default",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":97,"column":18},"end":{"line":97,"column":49}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":12},"end":{"line":97,"column":66}}})) != null ? stack1 : "")
    + "\n            value=\"default\"\n          >\n            "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"standard",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Standard"},"data":data,"loc":{"start":{"line":100,"column":12},"end":{"line":100,"column":81}}}))
    + "\n          </option>\n        </select>\n        <label class=\"form-label\" for=\"user_role\">\n          "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"role",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Role"},"data":data,"loc":{"start":{"line":104,"column":10},"end":{"line":104,"column":71}}}))
    + "\n        </label>\n      </div>\n    </div>\n\n    <button\n      class=\"btn btn-outline-danger mt-3 w-100\"\n      data-name=\"remove-button\"\n      type=\"button\"\n    >\n      <i class=\"fa-minus fas fa-fw\"></i> "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias3,"remove",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Remove User"},"data":data,"loc":{"start":{"line":114,"column":41},"end":{"line":114,"column":111}}}))
    + "\n    </button>\n  </div>\n</div>\n";
},"useData":true});