var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form class=\"mb-3\" id=\"new_user\" action=\"/invitation\" accept-charset=\"UTF-8\" method=\"post\">\n  <div class=\"accordion accordion-flush\" id=\"new-users-container\"></div>\n\n  <div class=\"accordion accordion-flush\" id=\"invitation-forms\">\n  </div>\n\n  <button class=\"btn btn-outline-primary mt-3 w-100\" id=\"add-user-button\" type=\"button\">\n    <i class=\"fa-plus fas fa-fw\"></i> "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"add",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Add User"},"data":data,"loc":{"start":{"line":8,"column":38},"end":{"line":8,"column":102}}}))
    + "\n  </button>\n\n  <hr>\n\n  <div class=\"actions d-grid mt-3\">\n    <button\n      class=\"btn btn-lg btn-primary\"\n      id=\"submit-button\"\n      type=\"button\"\n      disabled\n    >\n      <span class=\"spinner-border spinner-border-sm d-none\" role=\"status\" aria-hidden=\"true\"></span>\n      "
    + alias2(__default(require("../../shared/handlebars/t.js")).call(alias1,"invite",{"name":"t","hash":{"scope":"js.invitations.index","defaultValue":"Invite"},"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":21,"column":71}}}))
    + "\n    </button>\n  </div>\n</form>";
},"useData":true});